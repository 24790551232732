import { array, func, node, number, string } from "prop-types"
import { Fragment, useMemo, useState } from "react"
import splitInChunks from "lib/splitInChunks"

const SectionListScrollableHorizontal = ({
  className,
  items,
  pageUrl,
  rowsCount,
  renderItem,
  itemMore,
}) => {
  const isItemsEven = items.length % rowsCount === 0
  const [hideLastItem, setHideLastItem] = useState(isItemsEven)
  const [showingSkeletons, setShowingSkeletons] = useState(true)

  const rows = useMemo(() => {
    if (!items?.length) {
      setShowingSkeletons(true)
      setHideLastItem(false)

      return [...Array(rowsCount)].map((row, index) => {
        const isLast = index + 1 === rowsCount
        return [...Array(isLast ? 6 : 7)]
      })
    }

    if (Array.isArray(items[0])) {
      // Items is an array of arrays in this case
      setShowingSkeletons(false)
      setHideLastItem(false)
      return items
    }

    if (pageUrl) {
      setHideLastItem(isItemsEven)
    } else {
      setHideLastItem(false)
    }

    setShowingSkeletons(false)
    return splitInChunks(items, rowsCount)
  }, [items, rowsCount])

  return (
    <div className={className}>
      <div className="overflow-x-scroll scrollbar-hidden">
        <div className="inline-block box-x-wrapper">
          <div className="flex flex-col space-y-section">
            {rows.map((row, i) => {
              const isLastRow = i + 1 === rows.length
              return (
                <div className="flex space-x-section" key={i}>
                  {row.map((item, j) => {
                    const isLastItem = j + 1 === row.length

                    if (
                      rows.length > 1 &&
                      isLastRow &&
                      isLastItem &&
                      hideLastItem
                    )
                      return null

                    return (
                      <Fragment key={j}>
                        {item && renderItem && renderItem(item, j)}
                      </Fragment>
                    )
                  })}

                  {pageUrl && !showingSkeletons && isLastRow && itemMore}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

SectionListScrollableHorizontal.propTypes = {
  items: array.isRequired,
  className: string,
  pageUrl: string.isRequired,
  rowsCount: number,
  renderItem: func,
  itemMore: node,
}

SectionListScrollableHorizontal.defaultProps = { rowsCount: 1 }

export default SectionListScrollableHorizontal
