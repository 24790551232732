import React, { Fragment } from "react"
import { object, array } from "prop-types"
import Page from "components/Page"
import { OGP } from "components/Meta"
import { Endpoint, ApiPath } from "lib/endpoint"
import SubNavigation from "components/SubNavigation/SubNavigation"
import HomepageBanner from "components/Homepage/Banner"

import SectionFeaturedPosts from "components/Section/FeaturedPosts"
import SectionFeaturedVideos from "components/Section/FeaturedVideos"
import SectionPlaylists from "components/Section/Playlists"
import SectionVideos from "components/Section/Videos"
import SectionKeywords from "components/Section/Keywords"
import SectionCards from "components/Section/Cards"
import SectionDivider from "components/Section/Divider"

const dividerlessSectionTypes = ["additional"]

const Index = (props) => {
  var skippedDividers = [0]
  if (props.sections && props.sections.length > 0) {
    const newSkippedDividers = props.sections.flatMap(
      ({ type }, index) =>
        dividerlessSectionTypes.includes(type) && [index, index + 1],
    )
    skippedDividers = skippedDividers.concat(newSkippedDividers)
  }

  return (
    <>
      <OGP meta={props.meta} />
      <Page>
        {/* HOME画面のみ、いったん非表示（2022-06-01）<SubNavigation /> */}
        <HomepageBanner />

        <div className="flex flex-col space-y-wrapper py-wrapper">
          {props.sections &&
            props.sections.map((section, index) => {
              const { type } = section
              const showDivider = !skippedDividers.includes(index)

              if (type === "additional") {
                const { items } = section

                return (
                  <Fragment key={index}>
                    {showDivider && (
                      <SectionDivider className="box-x-wrapper" />
                    )}
                    <SectionFeaturedPosts items={items} />
                  </Fragment>
                )
              }

              if (type === "card") {
                const { title, sub_title, items } = section

                return (
                  <Fragment key={index}>
                    {showDivider && (
                      <SectionDivider className="box-x-wrapper" />
                    )}
                    <SectionCards
                      title={title}
                      subtitle={sub_title}
                      items={items}
                      key={index}
                    />
                  </Fragment>
                )
              }

              if (type === "special") {
                const { title, sub_title, page_url, items } = section

                return (
                  <Fragment key={index}>
                    {showDivider && (
                      <SectionDivider className="box-x-wrapper" />
                    )}

                    <SectionFeaturedVideos
                      title={title}
                      subtitle={sub_title}
                      pageUrl={page_url}
                      items={items}
                      key={index}
                    />
                  </Fragment>
                )
              }

              if (type === "playlist") {
                const { title, sub_title, mobile_rows_count, page_url, items } =
                  section

                return (
                  <Fragment key={index}>
                    {showDivider && (
                      <SectionDivider className="box-x-wrapper" />
                    )}

                    <SectionPlaylists
                      title={title}
                      subtitle={sub_title}
                      mobileRowsCount={mobile_rows_count}
                      pageUrl={page_url}
                      items={items}
                      key={index}
                    />
                  </Fragment>
                )
              }

              if (type === "movie") {
                const { title, sub_title, page_url, items, mobile_rows_count } =
                  section

                return (
                  <Fragment key={index}>
                    {showDivider && (
                      <SectionDivider className="box-x-wrapper" />
                    )}

                    <SectionVideos
                      title={title}
                      subtitle={sub_title}
                      pageUrl={page_url}
                      items={items}
                      mobileRowsCount={mobile_rows_count}
                      key={index}
                    />
                  </Fragment>
                )
              }

              if (type === "tag") {
                const { title, sub_title, mobile_rows_count, page_url, items } =
                  section

                return (
                  <Fragment key={index}>
                    {showDivider && (
                      <SectionDivider className="box-x-wrapper" />
                    )}

                    <SectionKeywords
                      title={title}
                      subtitle={sub_title}
                      mobileRowsCount={mobile_rows_count}
                      pageUrl={page_url}
                      items={items}
                      key={index}
                    />
                  </Fragment>
                )
              }

              return null
            })}
        </div>
      </Page>
    </>
  )
}

export async function getServerSideProps() {
  const res = await fetch(`${Endpoint}${ApiPath}/home`)
  const json = await res.json()

  const movieCount = 10000
  const title = "NobodySurf | Surfing Videos"
  const description = `Surf videos everyday, worldwide. NobodySurf is a surf video app and website where you can find world's best free-surfing videos.`
  const image = "https://cdn.nobodysurf.com/com/img/NobodySurf_Cover_w1800.png"
  const url = `https://nobodysurf.com`
  const rss = `https://nobodysurf.com/rss/`

  var meta = {
    title,
    description,
    image,
    url,
    rss,
    movieCount,
  }
  var props = {
    meta: meta,
    sections: json.sections,
  }
  return {
    props: props,
  }
}

Index.propTypes = {
  meta: object,
  banners: array,
  sections: array,
}

Index.defaultTypes = {
  banners: [],
  sections: [],
}

export default Index
